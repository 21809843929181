import React from 'react';
import _ from 'lodash';
import s from './ProductPrice.scss';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';

const ProductPrice = () => {
  const {
    state: { checkout },
  } = useState();
  const { currencyCode, locale, customVariant, presetVariants } =
    useControllerProps().giftCardStore;

  const formatCurrency = (value: number) => {
    try {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return formatter.format(value);
    } catch (error) {
      return value.toString();
    }
  };

  const productPrice = () => {
    if (checkout.purchaseOptions.variantId) {
      return priceVariant();
    } else {
      return priceRange();
    }
  };

  const priceVariant = () => {
    const selectedVariant = _.find(presetVariants, {
      id: checkout.purchaseOptions.variantId,
    });

    if (selectedVariant) {
      const variantPrice = formatCurrency(Number(selectedVariant?.price));
      if (selectedVariant?.price < selectedVariant?.value) {
        const variantValue = formatCurrency(Number(selectedVariant?.value));
        return `<span style="text-decoration: line-through;">${variantValue}</span> ${variantPrice}`;
      }

      return formatCurrency(Number(selectedVariant?.price));
    } else if (customVariant) {
      return formatCurrency(Number(checkout.purchaseOptions.customAmount));
    }
    return '';
  };

  const priceRange = () => {
    const minPriceVariant = _.minBy(presetVariants, 'price');
    const maxPriceVariant = _.maxBy(presetVariants, 'price');

    if (!minPriceVariant || !maxPriceVariant) {
      return formatCurrency(0);
    }

    const minPrice = minPriceVariant.price;
    const maxPrice = maxPriceVariant.price;

    if (minPrice === maxPrice) {
      return formatCurrency(Number(minPrice));
    }

    return `${formatCurrency(Number(minPrice))} - ${formatCurrency(
      Number(maxPrice),
    )}`;
  };

  return (
    <h6
      data-hook="rise-gift-card-product-price"
      className={s.price}
      dangerouslySetInnerHTML={{
        __html: productPrice(),
      }}
    />
  );
};
export default ProductPrice;
